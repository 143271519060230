var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "voffice-popup-box",
    {
      staticClass: "full-xxs",
      attrs: { "trigger-id": _vm.triggerId, "apply-label": _vm.applyLabel },
      on: { closed: _vm.onClosed }
    },
    [
      _c(
        "table",
        { staticClass: "guestbox" },
        [
          _vm._l(_vm.sections, function(l, what) {
            return _c("tr", { staticClass: "gb-row" }, [
              _c("td", { staticClass: "gb-td" }, [
                _c("label", [_vm._v(_vm._s(_vm.getFieldLabel(what)))])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "gb-td" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { disabled: !_vm.canDecr(what), type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.inc(what, -1)
                      }
                    }
                  },
                  [_vm._v("-")]
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "gb-td" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { disabled: !_vm.canInc(what), type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.inc(what, 1)
                      }
                    }
                  },
                  [_vm._v("+")]
                )
              ])
            ])
          }),
          _vm._v(" "),
          _vm.showAge
            ? _c("tr", [
                _c("td", { staticClass: "gb-td age" }, [
                  _c("label", [
                    _vm._v("Alter der Kinder zum Reisedatum (Bsp.: 4,12)")
                  ])
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "gb-td", attrs: { colspan: "2" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.childrenAges,
                        expression: "searchData.childrenAges"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "textbox" },
                    domProps: { value: _vm.searchData.childrenAges },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.searchData,
                          "childrenAges",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showAge
            ? _c("tr", [
                !_vm.isChildrenAgeEntered()
                  ? _c(
                      "td",
                      { staticClass: "gb-td", attrs: { colspan: "3" } },
                      [
                        _c(
                          "span",
                          { staticClass: "alert alert-danger warning-message" },
                          [_vm._v("Bitte geben Sie das Alter der Kinder ein.")]
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }